'use client';

import { useState, useEffect } from 'react';
import { RichText } from '@/components/rich-text';
import { SearchAutoComplete } from '@/features/search/ui/components/search-auto-complete';
import classNames from 'classnames';

export const Custom404 = ({
    heading,
    description,
    placeholder,
    searching_text,
    footnote,
    search,
    rich_text_heading,
    timeout,
}) => {
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (timeout) {
            setTimeout(() => {
                router.push('/');
            }, timeout * 1000);
        }
    }, []);

    const handleSubmit = (e) => {
        setIsSearching(true);
    };

    return (
        <section
            className={classNames('page404', {
                section:
                    rich_text_heading?.value ||
                    heading ||
                    !search ||
                    description?.value ||
                    footnote?.value,
            })}
        >
            <div className="container">
                {rich_text_heading?.value && (
                    <RichText
                        className="page404__heading"
                        data={rich_text_heading}
                    />
                )}
                {!rich_text_heading?.value && heading && (
                    <h1 className="page404__heading">{heading}</h1>
                )}
                <RichText className="page404__description" data={description} />
                {!search ? (
                    <div className="form__group">
                        <div className="form__validation">
                            <SearchAutoComplete
                                search_placeholder={placeholder}
                                isMobileView={true}
                                handleToggle={handleSubmit}
                            />
                        </div>
                        {isSearching && <small>{searching_text}</small>}
                    </div>
                ) : null}
                <RichText className="page404__footnote" data={footnote} />
            </div>
        </section>
    );
};
