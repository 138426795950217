'use client';

import { Loader } from '@/components/loader';
import { Custom404 } from '@/features/not-found/ui/components/custom-404';
import { ContentZone } from '@/components/content-zone';
import { useState, useEffect } from 'react';
import { PageLayout } from '@/features/site/ui/layouts/page-layout';
import { useCurrentLocale } from 'next-i18n-router/client';
import { i18nConfig } from '@/lib/i18n-config';

export const NotFoundView = ({ favicon }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const locale = useCurrentLocale(i18nConfig) || 'default';

    useEffect(() => {
        let mounted = true;

        const fetchPageData = async () => {
            try {
                const response = await fetch(`/api/not-found/${locale}`, {
                    headers: {
                        Accept: 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();

                if (mounted) {
                    setData(result);
                    setError(null);
                    document.title = result.title;
                    let link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                        link = document.createElement('link');
                        link.rel = 'icon';
                        document
                            .getElementsByTagName('head')[0]
                            .appendChild(link);
                    }
                    link.href = favicon;
                }
            } catch (err) {
                if (mounted) {
                    setError(err.message);
                    setData(null);
                }
                console.error('Failed to fetch 404 data:', err);
            }
        };

        fetchPageData();

        // Cleanup function
        return () => {
            mounted = false;
        };
    }, [locale]);

    if (error) {
        return <p>Something went wrong. Please try again later.</p>;
    }

    if (!data) {
        return <Loader />;
    }

    return (
        <PageLayout data={data}>
            {data.custom_404 && <Custom404 {...data.custom_404} />}
            {data.content_zone && <ContentZone {...data.content_zone} />}
        </PageLayout>
    );
};
